@tailwind base;
@tailwind components;
@tailwind utilities;


@import url("https://fonts.googleapis.com/css2?family=Urbanist&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&family=Urbanist:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&display=swap');
@import '@fortawesome/fontawesome-free/css/all.min.css';

body {
  margin: 0;
  font-family: "Urbanist", sans-serif;
  background-color: white;

}

@keyframes rankWave {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-rankWave {
  background-size: 200% 200%;
  animation: rankWave 2s linear infinite;
}

/* Custom CSS to remove number input arrows */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  /* Firefox */
}

.gradient {
  background-image: linear-gradient(to bottom,
      rgba(243, 244, 246, 0.1),
      rgba(0, 0, 0, 0.7));
}

.group:hover .group-hover\:translate-y-0 {
  transform: translateY(0);
}


.container {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.container::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}


header {
  display: flex;
  justify-content: center;
  align: center;
  padding: 15px;
  background: #3c3c3c;
  border-bottom: 3px solid #56e388;
}

header h1 {
  color: #eee;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

.faqs {
  width: 100%;
  max-width: 768px;
  margin: 0 auto;
  padding: 15px;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: all 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.2s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
  content: "-";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}



@media screen and (max-width: 480px) {
  .card {
    background-color: transparent;
    /* width: 200px; */
    /* height: 200px; */
    perspective: 1000px;
    transform: scale(1.5);
    align-items: center;
    margin: 10px 60px 10px;
  }

  .navbg {
    background-color: rgb(52 43 6 / 80%);
  }
}

.card {
  background-color: transparent;
  /* width: 320px; */
  /* height: 200px; */
  perspective: 1000px;
  transform: scale(1.5);
}

.card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.8s;
  transform-style: preserve-3d;

}

.card:hover .card-inner {
  transform: rotateY(180deg);
}

.card-front,
.card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #161616;
  color: white;
  border-radius: 10px;
}

.card-front p {
  padding-left: 20px;
  padding-top: 7px;
  font-size: 30px;
  font-weight: 500;
  font-family: 'Cairo', sans-serif;
  font-family: 'Jomhuria', 'cursive';
  font-family: 'Rubik', sans-serif;
}

.card-back {
  backface-visibility: hidden;
  transform: rotateY(180deg);
}

.mc-logo {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
}

.red,
.yellow {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.yellow {
  background-color: #fff;
}

.red {
  background-color: gold;
  opacity: 70%;
  transform: translateX(20px);
}

.sound-logo {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  transform: scale(0.7);
}

.sound-logo .c1,
.sound-logo .c2,
.sound-logo .c3,
.sound-logo .c4 {
  position: absolute;
  background-color: transparent;
  border: 3px solid transparent;
  border-right-color: white;
  border-radius: 50%;
}

.sound-logo .c1 {
  width: 40px;
  height: 40px;
}

.sound-logo .c2 {
  top: 5px;
  right: 6px;
  width: 30px;
  height: 30px;
}

.sound-logo .c3 {
  top: 10px;
  right: 12px;
  width: 20px;
  height: 20px;
}

.sound-logo .c4 {
  top: 15px;
  right: 18px;
  width: 10px;
  height: 10px;
}

.mid-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* .mid-logo img {
  width: 240px;
} */

.sim {
  position: absolute;
  top: 33%;
  left: 15%;
  width: 45px;
  height: 30px;
  background-color: #CFCECE;
  border-radius: 5px;
  border: 1px solid black;
}

.sim::before {
  content: '';
  position: absolute;
  top: 22.5%;
  width: 100%;
  height: 50%;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.sim::after {
  content: '';
  position: absolute;
  left: 22.5%;
  width: 50%;
  height: 100%;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

/********* End Front Side *********/


/****** Start Back Side*******/

.top-txt {
  position: absolute;
  width: 100%;
  top: 2%;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 6px;
  text-transform: uppercase;
}

.rec {
  position: absolute;
  top: 10%;
  width: 100%;
  height: 20%;
  background-color: black;
}

.auth-txt {
  position: absolute;
  top: 33%;
  width: 45%;
  padding-left: 10px;
  font-size: 5px;
  text-transform: uppercase;
}

.rec-nums {
  position: absolute;
  width: 41%;
  height: 15%;
  top: 40%;
  margin-left: 10px;
  background-color: #CFCECE;
  color: black;
}

.rec-nums::before {
  content: '123';
  position: absolute;
  width: 100%;
  height: 100%;
  right: -100px;
  bottom: -11px;
  transform: skewX(15deg);
}

.help-txt {
  position: absolute;
  top: 58%;
  width: 80%;
  margin-left: 10px;
  font-size: 5px;
  font-weight: 300;
  font-family: 'Cairo', sans-serif;
  font-family: 'Jomhuria', cursive;
  font-family: 'Rubik', sans-serif;
}

.nums {
  position: absolute;
  top: 70%;
  margin-left: 20px;
  word-spacing: 14px;
}

.valid {
  position: absolute;
  top: 85%;
  margin-left: 10px;
  width: 10%;
  font-size: 6px;
  text-transform: uppercase;
  line-height: 0.5;
}

.valid::after {
  content: '01/21';
  font-size: 12px;
  margin-left: 10px;
}

.act {
  position: absolute;
  top: 85%;
  margin-left: 90px;
  width: 10%;
  font-size: 6px;
  text-transform: uppercase;
  line-height: 0.5;
}

.act::after {
  content: '12345678';
  font-size: 12px;
  margin-left: 10px;
}

.prepaid {
  position: absolute;
  top: 71%;
  left: 82.5%;
  font-size: 11px;
  font-family: 'Cairo', sans-serif;
  font-family: 'Jomhuria', 'cursive';
  font-family: 'Rubik', sans-serif;
}

.mark {
  position: absolute;
  top: 80%;
  left: 80%;
  width: 50px;
  height: 30px;
  background: rgba(255, 255, 255, 0.2);
  background: linear-gradient(0deg, rgba(243, 239, 239, 0.479)50%, rgba(255, 255, 255, 0.7)100%);
  border-radius: 50px;
  overflow: hidden;
}

.mark::before {
  content: '';
  position: absolute;
  top: -13%;
  left: -15%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #CFCECE;
  border: 1px solid white;
  z-index: 2;
}

.mark::after {
  content: '';
  position: absolute;
  top: 18%;
  left: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #CFCECE;
}

.banque-img {
  position: absolute;
  top: 33%;
  left: 92%;
  width: 13px;
  height: 13px;
  filter: grayscale(1);
}

.banque-txt {
  position: absolute;
  top: 33%;
  left: 79.5%;
  width: 40px;
  height: 13px;
  filter: invert(1);
}

/********* End Back Side *********/

.copy {
  position: absolute;
  left: 0;
  bottom: 0;
  margin: 5px;
  color: white;
  font-size: 14px;
  font-weight: 300;
  font-family: 'Cairo', sans-serif;
  font-family: 'Jomhuria', 'cursive';
  font-family: 'Rubik', sans-serif;
}



.bbw {

  background-color: #161616;
}

.navbg {
  box-shadow: 0rem 0.25rem 0.375rem -0.0625rem rgba(20, 20, 20, 0.12), 0rem 0.125rem 0.25rem -0.0625rem rgba(20, 20, 20, 0.07);
  background: transparent;
  background-color: rgb(52 43 6 / 50%);
}


/* user Page  CSS*/
.userBlueBg {
  background-image: url("../src/assets/images/userBlueBg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed !important;
  height: 100%;
  z-index: -999;

}
.scrollable-content {
  overflow-y: auto;
  
}

.userBlureBg {
  background: rgb(9, 9, 121);
  background: linear-gradient(90deg, rgba(9, 9, 121, 0.8575805322128851) 0%, rgba(9, 9, 121, 0.865983893557423) 100%);
}
.inactive {
    color: white;
    background-color: red;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    font-weight: 500;
}
.expired {
  color: white;
  background-color: rgb(51, 8, 8);
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  font-weight: 500;
}
.blocked {
  color: white;
  background-color: rgb(117, 15, 1);
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  font-weight: 500;
}
.actives {
    color: white;
    background-color: green;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
    font-weight: 500;
}

.pending {
  color: white;
  background-color: rgb(208, 149, 2);
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  font-weight: 500;
}
.renewed {
  color: white;
  background-color: rgb(27, 88, 24);
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  font-weight: 500;
}

/* user Page  CSS*/
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.modal {
  position: fixed;
  inset: 0;
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.modal img {
  max-width: 100%;
  max-height: 80%;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
}

.animate-fade {
  animation: fadeInOut 2s infinite;
}

/* Neon border for rows */
.neon-border {
  border: 2px solid #00ffcc; /* Neon color */
  box-shadow: 0 0 10px #00ffcc, 0 0 20px #00ffcc, 0 0 30px #00ffcc;
  animation: neonGlow 1.5s infinite alternate;
}

/* Optional animation for the neon effect */
@keyframes neonGlow {
  from {
    box-shadow: 0 0 5px #00ffcc, 0 0 10px #00ffcc, 0 0 15px #00ffcc;
  }
  to {
    box-shadow: 0 0 15px #00ffcc, 0 0 30px #00ffcc, 0 0 45px #00ffcc;
  }
}
